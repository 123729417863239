:root{
  --three-level-title-font-size:1.5rem;
  --font-Cambria:Cambria;
  --body-font-size:1.125rem;
}

.font-Cambria-blod{
  font-family: var(--font-Cambria)!important;
  font-weight: bold!important;
}

.w-container{
    min-width: 960px;
    width:62.5vw;
    max-width: 1600px;
    margin: auto;
  }

.listBox .listcontent{
margin-bottom:30px;
}

.listText .content-title{
    font-size: var(--three-level-title-font-size);
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .listText .content-title>span.time{
    font-size: var(--body-font-size);
    color: #666666;
  }
  .listText .content-body{
    font-size: var(--body-font-size);
    color: #666666;
    font-family: Arial;
    line-height: 36px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin: 15px 0 10px;
    min-height: 105px;
    /* height: 135px; */
  }
  .listText .content-body p{
    margin:0;
  }

  .listItem{
    width: 100%;
    background: #FFFFFF;
    padding: 20px;
    display: grid;
    grid-template-columns: 400px calc(100% - 430px);
    grid-gap: 30px;
    margin-bottom: 10px;
  }


.listImage{
  width:100%;
  height: 100%;
  overflow-y: hidden;
}
.listImage{
  width: 100%;
  height: 203px;
  overflow: hidden;
}
.listImage>img{
  width: 100%;
  height:100%;
  object-fit: cover;
  transform: scale(1);
  transition:transform .5s ease ;
}
.listItem:hover{
  cursor:pointer;
  box-shadow: 0 1px 2px -2px rgba(0,0,0,.16), 0 3px 6px 0 rgba(0,0,0,.12), 0 5px 12px 4px rgba(0,0,0,.09) !important;
}
.listItem:hover .listImage>img{
  transform: scale(1.1);
}


.content-btn button{
  width:120px;
  font-size: 1rem!important;
  font-family: var(--font-Cambria);
  font-weight: bold;
}
.content-btn button i{
margin-left:10px;
}



.listPage .pagination{
  display: flex;
  justify-content: center;
}
.pagination button{
  background-color: #fff;
  font-size: 1rem;
}

.pagination button.Mui-selected:hover{
  background-color: #009764;
}
.pagination button:hover{
  background-color: rgba(0, 151, 100, 0.04);
}
.pagination button.Mui-selected{
  color: #fff;
  border: 1px solid #009764;
  background-color: #009764;

}
.pagination .MuiPaginationItem-previousNext{
  background-color: transparent;
  border: none;
  font-family: Cambria;
  font-weight: bold;
  color: #333333;
}
.pagination .MuiPaginationItem-previousNext:hover{
  background-color: transparent;
}